import React, { useEffect, useState, useContext } from "react";
import "./style.css";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ieclogo from "../../../assets/images/ice-logo.jpeg";
import {
  CButton,
  CCol,
  CForm,
  CFormInput,
  CFormSelect,
  CImage,
  CInputGroup,
  CInputGroupText,
  CRow,
} from "@coreui/react";
import { LOGIN_API } from "../../../AllApi/loginApi";
import UserContext from "../../../UserContext";
import LoginNavbar from "./LoginNavbar";
import { GiCheckMark } from "react-icons/gi";

const Login = () => {
  const user = useContext(UserContext);
  const navigate = useNavigate();
  const [loginData, setLoginData] = useState({
    username: "",
    password: "",
  });
  const [login, setLogin] = useState("Staff");

  useEffect(() => {
    user.setOTPPage("false");
    user.setResetEmail("");
  }, []);
  // Login data change function
  const handleChange = (e) => {
    setLoginData({ ...loginData, [e.target.name]: e.target.value });
  };

  // Login data submit function
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!loginData.username) {
      toast.error("Username is required..", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (!loginData.password) {
      toast.error("Password is required..", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      LOGIN_API.Signin(loginData).then((response) => {
        if (response.status === 200) {
          user.setCurrentUser(response.data.staff_id);
          user.setUserRole(response.data.role);
          user.setAuthToken(response.token);
          user.setMessage(response.message);
          localStorage.setItem("user-role", response.data.role);
          localStorage.setItem("iec-active-user", response.data.staff_id);
          navigate(`/${response.role}/dashboard`);
        } else {
          toast.error(response.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
  };
  return (
    <>
      <ToastContainer />
      <div className="top-content">
        <div className="inner-bgs">
          <div className="container-fluid">
            <div className="row">
              {/* <div className="bgoffsetbg"> */}
              <div className="col-lg-4 col-md-6 col-sm-12 bgoffsetbg">
                <div
                  className="loginbg"
                  style={{ width: "-webkit-fill-available" }}
                >
                  <div className="form-top">
                    <div className="form-top-left logowidth">
                      <img
                        src={ieclogo}
                        style={{ borderRadius: "50%", height: "90px" }}
                        alt="Colege ERP"
                        className="mt-3"
                      />
                    </div>
                  </div>
                  <div className="form-bottom">
                    <h3 className="font-white bolds text-center">{login} Login</h3>
                    <form onSubmit={handleSubmit}>
                      <input
                        type="hidden"
                        name="ci_csrf_token"
                        defaultValue=""
                      />{" "}
                      <div className="form-group has-feedback">
                        <input
                          type="text"
                          id="username"
                          name="username"
                          placeholder="Username"
                          onChange={handleChange}
                          value={loginData.username}
                          className="form-username form-control input-text"
                        />
                        <span className="fa fa-envelope form-control-feedback" />
                        <span className="text-danger" />
                      </div>
                      <div className="form-group has-feedback">
                        <input
                          type="password"
                          name="password"
                          placeholder="Password"
                          onChange={handleChange}
                          value={loginData.password}
                          className="form-password form-control input-text"
                          id="form-password"
                        />
                        <span className="fa fa-lock form-control-feedback" />
                        <span className="text-danger" />
                      </div>
                      <button type="submit" className="btn btns">
                        Sign In
                      </button>
                    </form>
                    {/* <div
                      className="btn-group justifieds w-100"
                      style={{ marginTop: 10 }}
                    >
                      <NavLink
                        // to="/login"
                        className="btn btn-primary width50"
                        onClick={() => setLogin("Admin")}
                        style={{
                          background: login === "Admin" ? "#4aa64e" : "#1bbed3",
                        }}
                      >
                        {login === "Admin" ? (
                          // <i className="fa-sharp fa-solid fa-check ispace" />
                          <GiCheckMark />
                        ) : (
                          <i className="fa fa-user ispace" />
                        )}{" "}
                        Admin
                      </NavLink>
                      <NavLink
                        // to="/teacher/login"
                        className="btn btn-primary width100"
                        onClick={() => setLogin("Teacher")}
                        style={{
                          background:
                            login === "Teacher" ? "#4aa64e" : "#1bbed3",
                        }}
                      >
                        {login === "Teacher" ? (
                          // <i className="fa-sharp fa-solid fa-check ispace" />
                          <GiCheckMark />
                        ) : (
                          <i className="fa fa-user ispace" />
                        )}{" "}
                        Teacher
                      </NavLink>
                      <NavLink
                        className="btn btn-primary width150"
                        onClick={() => setLogin("Librarian")}
                        style={{
                          background:
                            login === "Librarian" ? "#4aa64e" : "#1bbed3",
                        }}
                      >
                        {login === "Librarian" ? (
                          // <i className="fa-sharp fa-solid fa-check ispace" />
                          <GiCheckMark />
                        ) : (
                          <i className="fa fa-user ispace" />
                        )}{" "}
                        Librarian
                      </NavLink>
                    </div> */}
                    <p style={{ marginTop: 15 }}>
                      <NavLink
                        to="/user/login"
                        style={{ color: "#535353" }}
                        className="forgot pull-right"
                      >
                        {" "}
                        <i className="fa fa-user" /> User Login
                      </NavLink>{" "}
                    </p>
                    <p>
                      <NavLink className="forgot" to="/forgotpassword">
                        <i className="fa fa-key" /> Forgot Password?
                      </NavLink>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-md-6 col-sm-12 nopadding-2">
                <div
                  className="d-flex align-items-center text-wrap flex-column justify-content-center bg-position-sm-left bg-position-lg-center"
                  style={{
                    background:
                      'url("https://demo.smart-school.in/uploads/school_content/login_image/1663064530-1070210809632059d2b8b0b!1662796232-1721792380631c41c80d038!login_bg3.jpg") no-repeat',
                    backgroundSize: "cover",
                    opacity:"1"
                  }}
                >
                  <div className=" bg-shadow-remove ">
                    <h3 className="h3">What's New In IEC College of Engineering </h3>
                    <div className="loginright mCustomScrollbar _mCS_1">
                      <div
                        id="mCSB_1"
                        className="mCustomScrollBox mCS-light mCSB_vertical mCSB_inside"
                        style={{ maxHeight: "none" }}
                        tabIndex={0}
                      >
                        <div
                          id="mCSB_1_container"
                          className="mCSB_container"
                          style={{
                            position: "relative",
                            top: "-30px",
                            left: 0,
                          }}
                          dir="ltr"
                        >
                          {/* <div className="messages">
                            <h4>Teachers' Day Celebration</h4>
                            <p>
                              Teachers’ Day was celebrated in school with great
                              enthusiasm on Thursday, 5th September. The...{" "}
                              <a
                                className="more"
                                href="https://demo.smart-school.in/read/teachers-day-celebration-1"
                                target="_blank"
                              >
                                Read More
                              </a>
                            </p>
                            <div className="logdivider" />
                            <h4>
                              The Opening Ceremony of Computer Science Month
                            </h4>
                            <p>
                              Computer science
                              is a rapidly growing field that is...{" "}
                              <a
                                className="more"
                                href="https://demo.smart-school.in/read/the-opening-ceremony-of-computer-science-month"
                                target="_blank"
                              >
                                Read More
                              </a>
                            </p>
                            <div className="logdivider" />
                            <h4>Class III to V - Patriotic Song Competition</h4>
                            <p>
                              A patriotic song competition will be held for
                              students of classes III to V, encouraging them
                              to...{" "}
                              <a
                                className="more"
                                href="https://demo.smart-school.in/read/class-iii-to-v-patriotic-song-competition"
                                target="_blank"
                              >
                                Read More
                              </a>
                            </p>
                            <div className="logdivider" />
                            <h4>Webinar on Time Management.</h4>
                            <p>
                              Dear Parents/Guardians and Students, &nbsp; &nbsp;
                              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;...{" "}
                              <a
                                className="more"
                                href="https://demo.smart-school.in/read/webinar-on-time-management"
                                target="_blank"
                              >
                                Read More
                              </a>
                            </p>
                            <div className="logdivider" />
                            <h4>
                              Webinar for the Students of Class IX to XII on
                              Career information.
                            </h4>
                            <p>
                              Dear Parents/Guardians and Student, &nbsp; &nbsp;
                              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;...{" "}
                              <a
                                className="more"
                                href="https://demo.smart-school.in/read/webinar-for-the-students-of-class-ix-to-xii-on-career-information"
                                target="_blank"
                              >
                                Read More
                              </a>
                            </p>
                            <div className="logdivider" />
                          </div> */}
                        </div>
                        <div
                          id="mCSB_1_scrollbar_vertical"
                          className="mCSB_scrollTools mCSB_1_scrollbar mCS-light mCSB_scrollTools_vertical"
                          style={{ display: "block" }}
                        >
                          <div className="mCSB_draggerContainer">
                            <div
                              id="mCSB_1_dragger_vertical"
                              className="mCSB_dragger"
                              style={{
                                display: "block",
                                height: 126,
                                maxHeight: 260,
                                top: 7,
                              }}
                            >
                              <div
                                className="mCSB_dragger_bar"
                                style={{ lineHeight: 0 }}
                              />
                            </div>
                            <div className="mCSB_draggerRail" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*./col-lg-6*/}
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
