import React from "react";
import { IoArrowForwardCircleOutline,IoSpeedometerOutline  } from "react-icons/io5";
import { CNavGroup, CNavItem } from "@coreui/react";
import { FaAngleDoubleRight, FaBook, FaBuilding, FaBus, FaCalendarCheck, FaCalendarPlus, FaCheckSquare, FaMoneyBill, FaTv, FaUserPlus } from "react-icons/fa";
import { FaMap } from "react-icons/fa6";

const Sidebar_Menu = [
  {
    component: CNavItem,
    name: "Dashboard",
    to: "/student/dashboard",
    icon: <FaTv className=" nav-icon"/>,
  },
  {
    component: CNavItem,
    name: "My Profile",
    to: "/student/profile",
    icon: <FaUserPlus className=" nav-icon"/>,
  },
  {
    component: CNavItem,
    name: "Fees",
    to: "/student/getfees",
    icon: <FaMoneyBill className=" nav-icon"/>,
  },
  {
    component: CNavItem,
    name: "Class Timetable",
    to: "/student/timetable",
    icon: <FaCalendarPlus className=" nav-icon"/>,
  },
  {
    component: CNavItem,
    name: "Apply Leave",
    to: "/student/leavelist",
    icon: <FaCheckSquare className=" nav-icon"/>,
  },
  {
    component: CNavItem,
    name: "Attendance",
    to: "/student/attendance/list",
    icon: <FaCalendarCheck className=" nav-icon"/>,
  },
  {
    component: CNavGroup,
    name: "Examinations",
    to: "/student/examinations/list",
    icon: <FaMap className=" nav-icon"/>,
    items: [
      {
        component: CNavItem,
        name: "Exam Schedule",
        to:"/student/examschedule",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      },
      {
        component: CNavItem,
        name: "Exam Result",
        to:"/student/examresult",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      },
    ],
  },
  {
    component: CNavItem,
    name: "Library",
    to: "/student/library",
    icon: <FaBook className=" nav-icon"/>,
  },
  {
    component: CNavItem,
    name: "Transport",
    to: "/student/transport",
    icon: <FaBus className=" nav-icon"/>,
  },
  {
    component: CNavItem,
    name: "Hostel",
    to: "/student/hostel",
    icon: <FaBuilding className=" nav-icon"/>,
  },
];

export default Sidebar_Menu;
