import React, { useContext, useState } from "react";
import "../../../Css/login.css";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ieclogo from "../../../assets/images/ice-logo.jpeg";
import {
  CButton,
  CCol,
  CForm,
  CFormInput,
  CImage,
  CInputGroup,
  CInputGroupText,
  CRow,
} from "@coreui/react";
import UserContext from "../../../UserContext";
import { REGISTER_API } from "../../../AllApi/addStaffApi";
import LoginNavbar from "../login/LoginNavbar";

const ForgotPasswordForm = () => {
  const navigate = useNavigate();
  const { resetEmail, setOTP, setResetEmail, setMessage } =
    useContext(UserContext);
  const [newPassword, setNewPassword] = useState("");

  const ResetPassword = (e) => {
    e.preventDefault();
    const data = {
      reset_password: newPassword,
      email: resetEmail,
    };
    REGISTER_API.ResetPassword(data).then(async (resp) => {
      if (resp.status === 200) {
        await setOTP("");
        await setResetEmail("");
        await setMessage(resp.message);
        navigate("/login");
      }
    });
  };
  return (
    <>
      <ToastContainer />
      <div className="top-content">
        <div className="inner-bgs">
          <div className="container-fluid">
            <div className="row">
              {/* <div className="bgoffsetbg"> */}
              <div className="col-lg-4 col-md-6 col-sm-12 bgoffsetbg">
                <div
                  className="loginbg"
                  style={{ width: "-webkit-fill-available" }}
                >
                  <div className="form-top">
                    <div className="form-top-left logowidth">
                      <img
                        src={ieclogo}
                        style={{ borderRadius: "50%", height: "90px" }}
                        alt="Colege ERP"
                      />
                    </div>
                  </div>
                  <div className="form-bottom">
                    <CForm onSubmit={ResetPassword}>
                      <CRow>
                        <CCol md={12} sm={10} xs={10} className="login-section">
                          <label>New Password</label>
                          <CInputGroup className="mb-2">
                            <CFormInput
                              type="text"
                              name="password"
                              className="input-text bg-light"
                              onChange={(e) => setNewPassword(e.target.value)}
                              value={newPassword}
                            />
                          </CInputGroup>
                          <CRow className="mt-4">
                            <CCol lg={12}>
                              <CButton
                                // style={{ backgroundColor: "#C21807" }}
                                className="btn btns"
                                type="submit"
                              >
                                Reset Password
                              </CButton>
                            </CCol>
                          </CRow>
                          <p className="text-center">
                            <NavLink
                              className="forgot"
                              style={{ fontSize: "16px" }}
                              to="/login"
                            >
                              <i class="fa-solid fa-right-to-bracket"></i> Login
                            </NavLink>
                          </p>
                        </CCol>
                      </CRow>
                    </CForm>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-md-6 col-sm-12 nopadding-2">
                <div
                  className="d-flex align-items-center text-wrap flex-column justify-content-center bg-position-sm-left bg-position-lg-center"
                  style={{
                    background:
                      'url("https://demo.smart-school.in/uploads/school_content/login_image/1663064530-1070210809632059d2b8b0b!1662796232-1721792380631c41c80d038!login_bg3.jpg") no-repeat',
                    backgroundSize: "cover",
                  }}
                >
                  <div className=" bg-shadow-remove ">
                    <h3 className="h3">
                      What's New In IEC College of Engineering{" "}
                    </h3>
                    <div className="loginright mCustomScrollbar _mCS_1">
                      <div
                        id="mCSB_1"
                        className="mCustomScrollBox mCS-light mCSB_vertical mCSB_inside"
                        style={{ maxHeight: "none" }}
                        tabIndex={0}
                      >
                        {/* <div
                          id="mCSB_1_container"
                          className="mCSB_container"
                          style={{
                            position: "relative",
                            top: "-30px",
                            left: 0,
                          }}
                          dir="ltr"
                        >
                          <div className="messages">
                            <h4>Teachers' Day Celebration</h4>
                            <p>
                              Teachers’ Day was celebrated in school with great
                              enthusiasm on Thursday, 5th September. The...{" "}
                              <a
                                className="more"
                                href="https://demo.smart-school.in/read/teachers-day-celebration-1"
                                target="_blank"
                              >
                                Read More
                              </a>
                            </p>
                            <div className="logdivider" />
                            <h4>
                              The Opening Ceremony of Computer Science Month
                            </h4>
                            <p>
                              Computer science is a rapidly growing field that
                              is...{" "}
                              <a
                                className="more"
                                href="https://demo.smart-school.in/read/the-opening-ceremony-of-computer-science-month"
                                target="_blank"
                              >
                                Read More
                              </a>
                            </p>
                            <div className="logdivider" />
                            <h4>Class III to V - Patriotic Song Competition</h4>
                            <p>
                              A patriotic song competition will be held for
                              students of classes III to V, encouraging them
                              to...{" "}
                              <a
                                className="more"
                                href="https://demo.smart-school.in/read/class-iii-to-v-patriotic-song-competition"
                                target="_blank"
                              >
                                Read More
                              </a>
                            </p>
                            <div className="logdivider" />
                            <h4>Webinar on Time Management.</h4>
                            <p>
                              Dear Parents/Guardians and Students, &nbsp; &nbsp;
                              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;...{" "}
                              <a
                                className="more"
                                href="https://demo.smart-school.in/read/webinar-on-time-management"
                                target="_blank"
                              >
                                Read More
                              </a>
                            </p>
                            <div className="logdivider" />
                            <h4>
                              Webinar for the Students of Class IX to XII on
                              Career information.
                            </h4>
                            <p>
                              Dear Parents/Guardians and Student, &nbsp; &nbsp;
                              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;...{" "}
                              <a
                                className="more"
                                href="https://demo.smart-school.in/read/webinar-for-the-students-of-class-ix-to-xii-on-career-information"
                                target="_blank"
                              >
                                Read More
                              </a>
                            </p>
                            <div className="logdivider" />
                          </div>
                        </div> */}
                        <div
                          id="mCSB_1_scrollbar_vertical"
                          className="mCSB_scrollTools mCSB_1_scrollbar mCS-light mCSB_scrollTools_vertical"
                          style={{ display: "block" }}
                        >
                          <div className="mCSB_draggerContainer">
                            <div
                              id="mCSB_1_dragger_vertical"
                              className="mCSB_dragger"
                              style={{
                                display: "block",
                                height: 126,
                                maxHeight: 260,
                                top: 7,
                              }}
                            >
                              <div
                                className="mCSB_dragger_bar"
                                style={{ lineHeight: 0 }}
                              />
                            </div>
                            <div className="mCSB_draggerRail" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*./col-lg-6*/}
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPasswordForm;
