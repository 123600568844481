import { REACT_APP_BASE_PATH } from "./baseUrl";

export const STUDENT_DETAIL_API = {
  getAllStudentList: async () => {
    // console.log("calling")
    try {
      const response = await fetch(`${REACT_APP_BASE_PATH}/student/list`, {
        method: "get",
      });
      const returnData = await response.json();
      return returnData;
    } catch (error) {
      return error;
    }
  },

  GetStudentById: async (id) => {
    // console.log(data.get("photo"))
    try {
      const response = await fetch(
        `${REACT_APP_BASE_PATH}/student/data/${id}`,
        {
          method: "get",
        }
      );
      const returnData = await response.json();
      return returnData;
    } catch (error) {
      return error;
    }
  },
  GetStudentByRollNo: async (id) => {
    // console.log(data.get("photo"))
    try {
      const response = await fetch(
        `${REACT_APP_BASE_PATH}/student/data/byrollno/${id}`,
        {
          method: "get",
        }
      );
      const returnData = await response.json();
      return returnData;
    } catch (error) {
      return error;
    }
  },
  getStudentByData: async (data) => {
    // console.log(data)
    try {
      const response = await fetch(
        `${REACT_APP_BASE_PATH}/student/data?course=${data.course}&branch=${data.branch}&semester=${data.semester}&section=${data.section}&subject=${data.subject}&subjectCode=${data.subjectCode}`,
        {
          method: "get",
        }
      );
      const returnData = await response.json();
      return returnData;
    } catch (error) {
      return error;
    }
  },
  getStudentBySubSection: async (data) => {
    // console.log(data)
    try {
      const response = await fetch(
        `${REACT_APP_BASE_PATH}/student/databysubsection?course=${data.course}&branch=${data.branch}&semester=${data.semester}&section=${data.section}&subSection=${data.subSection}&subject=${data.subject}&subjectCode=${data.subjectCode}`,
        {
          method: "get",
        }
      );
      const returnData = await response.json();
      return returnData;
    } catch (error) {
      return error;
    }
  },
  getStudentBySection: async (data) => {
    // console.log(data)
    try {
      const response = await fetch(
        `${REACT_APP_BASE_PATH}/student/databysection?course=${data.course}&branch=${data.branch}&semester=${data.semester}&section=${data.section}`,
        {
          method: "get",
        }
      );
      const returnData = await response.json();
      return returnData;
    } catch (error) {
      return error;
    }
  },
  disableStudent: async (data) => {
    try {
      const response = await fetch(
        `${REACT_APP_BASE_PATH}/student/disablestudent`,
        {
          method: "post",
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const returnData = await response.json();
      return returnData;
    } catch (error) {
      return error;
    }
  },
  enableStudent: async (id) => {
    try {
      const response = await fetch(
        `${REACT_APP_BASE_PATH}/student/enablestudent/${id}`,
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const returnData = await response.json();
      return returnData;
    } catch (error) {
      return error;
    }
  },
  getDisableStudentList: async (data) => {
    try {
      const response = await fetch(
        `${REACT_APP_BASE_PATH}/student/disablelist`,
        {
          method: "post",
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const returnData = await response.json();
      return returnData;
    } catch (error) {
      return error;
    }
  },
  ChangePassword: async (data) => {
    // console.log(data.get("photo"))
    try {
      const response = await fetch(
        `${REACT_APP_BASE_PATH}/student/changepass`,
        {
          method: "put",
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const returnData = await response.json();
      return returnData;
    } catch (error) {
      return error;
    }
  },
};
