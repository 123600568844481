import React from "react";
import "../Css/admin.css";
import { useSelector, useDispatch } from "react-redux";
import {
  CContainer,
  CHeader,
  CHeaderBrand,
  CHeaderNav,
  CHeaderToggler,
  CNavLink,
  CNavItem,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilBell, cilEnvelopeOpen, cilList, cilMenu } from "@coreui/icons";
import imgLogo from "../assets/images/ice-logo.jpeg";

import { AppHeaderDropdown } from "./header/index";
import { AiOutlineMenu, AiOutlineMenuFold, AiOutlineMenuUnfold } from "react-icons/ai";

const AppHeader = () => {
  const dispatch = useDispatch();
  const sidebarShow = useSelector((state) => state.sidebarShow);

  return (
    <div className="sticky-top mb-3" style={{ backgroundColor: "#333",padding:".77rem" }}>
      <div className="container-fluid ">
        <div className="row">
          <div className="col-lg-8 g-0">
            <span
              onClick={() =>
                dispatch({ type: "set", sidebarShow: !sidebarShow })
              }
              style={{ cursor: "pointer",color:"#007fbf" }}
            >
              <AiOutlineMenu className="fs-4 fw-bold" />
              {/* {sidebarShow ? (
                <AiOutlineMenuFold className="fs-5 text-light" />
              ) : (
                <AiOutlineMenuUnfold className="fs-5 text-light" />
              )} */}
            </span>
            <span className="college-name mx-3">
              {process.env.REACT_APP_NAME}
            </span>
          </div>
          <div className="col-lg-4">
            <AppHeaderDropdown />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppHeader;
